import React from 'react';
import "../styles/ProjectBanner.css";
import { Link } from 'react-router-dom';

export const ProjectBanner = () => {
  return (
    <div className='project-banner'>
      <h2>Find Inspiration For Your Next Project</h2>
      <Link to="/portfolio">
        <button className='primary-1'>
          View Our Portfolio
        </button>
      </Link>
    </div>
  )
}
