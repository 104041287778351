import React from 'react';
import { Cards, PageHeading, ProjectBanner, Testimonials } from '../components';
import "../styles/Service.css";
import { Helmet } from "react-helmet";

export const CustomFurniture = () => {

  const customFurnitureCards = [
    {
      imageUrl: require('../assets/custom-bedframe-and-curtains.jpg'),
      imageAlt: "custom black and white bedframe and matching curtains",
      title: "Custom Bed Frames & Headboards",
      description: "Personalize your bedroom with our custom bed frames and headboards, crafted to your specifications for the perfect blend of style and comfort.",
    },
    {
      imageUrl: require('../assets/custom-sofa.jpg'),
      imageAlt: "custom black sofa and matching armchairs",
      title: "Custom Sofas & Armchairs",
      description: " Design your ideal seating with our custom sofas & armchairs, tailored to fit your space and lifestyle, offering durability, comfort, and timeless elegance.",
    },
    {
      imageUrl: require('../assets/upholstered-ottoman.jpg'),
      imageAlt: "custom black ottoman",
      title: "Custom Benches & Ottomans",
      description: "Enhance any space with our custom benches & ottomans, crafted to complement your decor and provide versatile seating solutions with impeccable craftsmanship.",
    }
  ]
  return (
    <div className='page service-page'>
      <Helmet>
        <title>Empire City Interiors - Custom Furniture In NYC</title>
        <meta
          name="description"
          content="From personalized bed frames and sofas to versatile benches, our skilled craftsmen bring your vision to life with impeccable attention to detail."
        />
      </Helmet>

      <PageHeading title="Custom Furniture In New York" />

      <div className='container pd-32'>

        <p className='service-description'>
          From personalized bed frames and sofas to versatile benches, our skilled craftsmen bring your vision to life with impeccable craftsmanship
          and attention to detail. Tailored to your specifications, our custom pieces seamlessly integrate style, comfort, and functionality,
          ensuring each furniture item reflects your unique taste and enhances the ambiance of your space.
        </p>
        <Cards cardList={customFurnitureCards} />
      </div>

      <ProjectBanner />

      <Testimonials />
    </div>
  )
}
