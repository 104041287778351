import { Route, Routes } from 'react-router';
import './App.css';
import { Footer } from './components/Footer';
import { Navbar } from './components/Navbar';
import { About, ContactUs, CustomFurniture, Fabrics, Home, Portfolio, Upholstery, WindowTreatments } from './pages';

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        {/* Main Nac */}
        <Route path="/" element={<Home />} />
        {/* <Route path="/about-us" element={<About />} /> */}
        <Route path="/window-treatments" element={<WindowTreatments />} />
        <Route path="/upholstery" element={<Upholstery />} />
        <Route path="/custom-furniture" element={<CustomFurniture />} />
        <Route path="/portfolio" element={<Portfolio />} />
        {/* <Route path="/fabrics" element={<Fabrics />} /> */}
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
