import { useEffect, useState } from 'react';
import { client } from '../client';

const useFetch = (endpoint, type) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {

            setLoading(true);

            try {

                let res = await client.getEntries({ content_type: endpoint });
                let cleanData = [];

                if (type === "images") {
                    res.items.map((item) => {

                        item.fields.images.toReversed().map((image) => {
                            cleanData.push({ text: image.fields.title, link: image.fields.file.url, type: image.fields.file.contentType })
                        })
                    })
                }
                else if (type === "review") {
                    cleanData = res.items.map((review) => {
                        return { author: review.fields.author, review: review.fields.review }
                    })
                }

                setData(cleanData);
                setLoading(false);
            }
            catch (error) {
                setLoading(false);
            }
        }

        fetchData();
    }, [endpoint])

    return { data, loading }
}

export default useFetch