import React from 'react';
import "../styles/Cards.css";

export const Cards = ({ cardList }) => {

  return (
    <div className='cards'>
      {cardList && cardList.map((card) => {
        return (
          <div className={'card' + (Object.keys(card).length === 0 ? " hidden" : "")}>
            <img src={card.imageUrl} alt={card.imageAlt} />
            <div>
              <h3>{card.title}</h3>
              <p>{card.description}</p>
              {card.buttonText && card.buttonLink &&
                <a href={card.buttonLink}>{card.buttonText} →</a>
              }
            </div>
          </div>
        )
      })}
    </div >
  )
}
