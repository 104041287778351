import React from 'react';
import { Cards, Testimonials } from '../components';
import useFetch from '../hooks/useFetch';
import "../styles/Home.css";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

export const Home = () => {

  const serviceCards = [
    {
      imageUrl: require('../assets/custom-panel-curtains.jpg'),
      imageAlt: "Modern curtain panels",
      title: "Window Treatments",
      description: "Transform your space with our exquisite selection of window treatments, including drapes, blinds, and shades.",
      buttonText: "Learn More",
      buttonLink: "/window-treatments",
    },
    {
      imageUrl: require('../assets/upholstered-residential-wall.jpg'),
      imageAlt: "Upholstered modern sofa",
      title: "Upholstery",
      description: "Give new life to your favorite furniture with our professional upholstery services, ensuring they look and feel their best.",
      buttonText: "Learn More",
      buttonLink: "/upholstery",
    },
    {
      imageUrl: require('../assets/custom-bed.jpg'),
      imageAlt: "Custom-made upholstered modern bedframe",
      title: "Custom Furniture",
      description: "Our handcrafted furniture pieces are tailored to your preferences, from elegant bedframes to luxurious sofas and more.",
      buttonText: "Learn More",
      buttonLink: "/custom-furniture",
    },
  ]

  const projectImages = useFetch('homepageProjectGallery', 'images');

  return (
    <div className='page home'>

      <Helmet>
        <title>Empire City Interiors - Window Treatments & Upholstery In NYC</title>
        <meta
          name="description"
          content="Your dream space is just a click away. Contact us to get started on your journey to a more stylish and comfortable home. We can't wait to bring your vision to life."
        />
      </Helmet>

      {/* HERO */}
      <div className='hero-section'>
        <div className='container pd-56'>
          <h1> Your Premier Source For <br />Window Treatments & Upholstery </h1>
          <p>
            Your dream space is just a click away. Contact us to get started on your journey to a more
            stylish and comfortable home. We can't wait to bring your vision to life.
          </p>
          <a href="tel:347-640-1116">
            <button className='primary-1'>
              Call Us Today To Get A FREE Quote
            </button>
          </a>
        </div>
      </div>

      {/* ABOUT */}
      <div className='about-section container pd-56'>
        <img src={require('../assets/long-custom-curtains.jpg')} alt="Custom-made long draped curtains" />
        <div>
          <h2>About Us</h2>
          <p className='subtitle'>Craftsmanship That Defines Empire City Interiors</p>
          <p>
            Our legacy, dating back to the year 2000, embodies a commitment to crafting exquisite home furnishings and elevating interior spaces. At Empire City Interiors, we redefine luxury by blending timeless craftsmanship with innovative design approaches, ensuring each custom furniture piece, drapery, and shade reflects unparalleled sophistication.
          </p>
          <p>
            Driven by a passion for perfection, our seasoned artisans and designers bring your vision to life, infusing every project with meticulous attention to detail and a touch of personalization. From custom beds and sofas to expert furniture upholstery, we prioritize unwavering client satisfaction, delivering not just furniture, but an experience tailored to your unique needs and preferences.
          </p>
          <p>
            Proudly headquartered in the heart of Manhattan, we extend our services beyond city limits, serving the dynamic landscapes of New York City and the Tri-State area. With Empire City Interiors, your interior dreams become a reality, where quality craftsmanship meets personalized service, and luxury knows no bounds.
          </p>
        </div>
      </div>

      {/* STATS */}
      <div className='stats-section'>
        <div className='container pd-32'>
          <div className='col'>
            <p className='stat-big'>800+</p>
            <p className='stat-small'>Successful Projects</p>
          </div>
          <div className='vl' />
          <hr />
          <div className='col'>
            <p className='stat-big'>23</p>
            <p className='stat-small'>Years Of Experience</p>
          </div>
          <div className='vl' />
          <hr />
          <div className='col'>
            <p className='stat-big'>100%</p>
            <p className='stat-small'>Customer Satisfaction</p>
          </div>
        </div>
      </div>

      {/* SERVICES */}
      <div className='services-section container pd-56'>

        <h2>Our Services</h2>
        <p className='subtitle'>Elevate Your Home with Our Expert Services</p>

        <Cards cardList={serviceCards} />
      </div>

      {projectImages && projectImages.data && projectImages.data.length !== 0 &&
        <div className='projects-section light-background'>
          <div className='container pd-32'>

            <h2>Latest Projects</h2>
            <p className='subtitle'>Inspiration For Your Next Home Project</p>

            <div className='images'>
              <img className="big-image" src={projectImages.data[0].link} alt={projectImages.data[0].text} />
              <div className='small-images'>
                <div className='row-1'>
                  <img src={projectImages.data[1].link} alt={projectImages.data[1].text} />
                  <img src={projectImages.data[2].link} alt={projectImages.data[2].text} />
                </div>
                <div className='row-2'>
                  <img src={projectImages.data[3].link} alt={projectImages.data[3].text} />
                  <img src={projectImages.data[4].link} alt={projectImages.data[4].text} />
                </div>
              </div>
            </div>
            <Link to="/portfolio">
              <button className='primary-2'>View Full Portfolio</button>
            </Link>
          </div>
        </div>
      }

      {/* TESTIMONIALS */}
      <Testimonials />

    </div >
  )
}
