import React from 'react';
import { Helmet } from "react-helmet";
import { Cards, PageHeading, ProjectBanner, Testimonials } from '../components';
import "../styles/Service.css";

export const WindowTreatments = () => {

  const draperyCards = [
    {
      imageUrl: require('../assets/ripplefold.jpg'),
      imageAlt: "ripple fold drapery",
      title: "Ripple Fold Drapery",
      description: "This simple design is a favorite of architects and interior designers. Easy to operate and highly functional. Glides effortlessly along a track (included)."
    },
    {
      imageUrl: require('../assets/tailored-pleat.jpg'),
      imageAlt: "tailored pleat drapery",
      title: "Tailored Pleat Drapery",
      description: "Featuring a sleek waterfall pleat, this drapery hangs beautifully and is meticulously hand-sewn to ensure a lasting look and functionality."
    },
    {
      imageUrl: require('../assets/pinch-pleat.jpg'),
      imageAlt: "pinch pleat drapery",
      title: "Pinch Pleat Drapery",
      description: "Each pleat is “pinched” 4 inches down from the top to create this classic look. A stiffener sewn into each pleat ensures excellent functionality."
    },
    {
      imageUrl: require('../assets/inverted-pleat.jpg'),
      imageAlt: "inverted pleat drapery",
      title: "Inverted Pleat Drapery",
      description: "Hand-stitched inverted pleats create a relaxed style and a humble elegance. At home in modern settings as well as traditional. Primarily a decorative style."
    },
    {
      imageUrl: require('../assets/grommet.jpg'),
      imageAlt: "grommet drapery",
      title: "Grommet Drapery",
      description: "Featuring hand-pressed grommets available in 5 designer finishes. Perfect for stylish, modern interiors. Primarily a decorative style."
    },
    {
      imageUrl: require('../assets/goblet.jpg'),
      imageAlt: "goblet drapery",
      title: "Goblet Drapery",
      description: "Style and sophistication of a bygone era. Each pleat is handcrafted, ensuring the goblet shape lasts for years to come. Primarily a decorative style."
    },
    {
      imageUrl: require('../assets/cubicle.jpg'),
      imageAlt: "cubicle drapery",
      title: "Cubicle Drapery",
      description: "Custom-made with small grommets that couple with hook carriers. Glides easily along a track (included). Draperies lay nearly flat while closed."
    },
    {
      imageUrl: require('../assets/rod-pocket.jpg'),
      imageAlt: "rod pocket drapery",
      title: "Rod Pocket Drapery",
      description: "A 3 ½ inch top pocket accommodates 4 of our designer hardware collections. Fabric gathers at desired widths. Not ideal for frequent use."
    },
    {}
  ]

  const valancesCards = [
    {
      imageUrl: require('../assets/cornice.jpg'),
      imageAlt: "fabric cornice",
      title: "Cornices",
      description: "Each cornice is handcrafted to your exact specifications from top quality wood covered by over 400 exclusive fabrics. 3 styles available.",
    },
    {
      imageUrl: require('../assets/solar-shade-valance-2.jpg'),
      imageAlt: "solar shade valance",
      title: "Roller + Solar Shade Valances",
      description: "All custom roller shades and solar shades can be outfitted with an upholstered or metal valance. In addition to concealing the control mechanisms, this will give your shade a clean, finished look.",
    },
    {
      imageUrl: require('../assets/roman-shade-valance.jpg'),
      imageAlt: "woven roman shade valance",
      title: "Roman + Woven Wood Shade Valances",
      description: "Nearly all custom roman shades and woven wood shades can be made to include a valance - a beautiful finishing touch for your treatment.",
    }
  ]

  return (
    <div className='page service-page window-page'>

      <Helmet>
        <title>Empire City Interiors - Window Treatments in NYC</title>
        <meta
          name="description"
          content="Enhance any room with our custom drapery solutions. From stationary panels to functioning drapes, we cater to various decorating styles, be it traditional, contemporary, elegant, or casual."
        />
      </Helmet>

      <PageHeading title="Window Treatments In New York" />

      <div className='container pd-32'>
        <h2>Custom Drapery</h2>
        <p className='service-description'>
          Enhance any room with our exquisite custom drapery solutions. Our wide selection of styles and fabrics allows
          you to add the perfect decorative touch, transforming your space with timeless elegance. From stationary panels
          to functioning drapes, we cater to various decorating styles, be it traditional, contemporary, elegant, or casual.
        </p>
        <Cards cardList={draperyCards} />
      </div>

      <div className='light-background'>
        <div className="container pd-32">
          <h2>Valances And Cornices</h2>
          <p className='service-description'>
            Elevate your windows with our custom fabric valances and cornices, transforming ordinary into extraordinary.
            Quality craftsmanship at an affordable price for a designer touch in your home.
          </p>
          <Cards cardList={valancesCards} />
        </div>
      </div>

      <ProjectBanner />

      <Testimonials />
    </div>
  )
}
