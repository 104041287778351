import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import burger from '../assets/burger-icon-2.png';
import logo from '../assets/empire-city-interiors-logo-black.png';
import xIcon from '../assets/x-icon-2.png';
import "../styles/Navbar.css";

export const Navbar = () => {

    const location = useLocation();
    const [showNavbar, setShowNavbar] = useState(false)

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
    }

    return (
        <nav className='navbar'>
            <div className='container'>
                <div className='logo'>
                    <Link to="/">
                        <img src={logo} alt="Empire City Interiors Logo" />
                    </Link>
                </div>
                <div className='menu-icon' onClick={handleShowNavbar}>
                    {showNavbar ?
                        <img src={xIcon} alt="close menu icon" />
                        :
                        <img src={burger} alt="burger menu icon" />
                    }
                </div>
                <div className={`nav-elements ${showNavbar && 'active'}`}>
                    <ul>
                        <li onClick={handleShowNavbar}>
                            <Link to="/window-treatments" className={location.pathname.includes("/window-treatments") ? "active" : ""}>Window Treatments</Link>
                        </li>
                        <li onClick={handleShowNavbar}>
                            <Link to="/upholstery" className={location.pathname.includes("/upholstery") ? "active" : ""}>Upholstery</Link>
                        </li>
                        <li onClick={handleShowNavbar}>
                            <Link to="/custom-furniture" className={location.pathname.includes("/custom-furniture") ? "active" : ""}>Custom Furniture</Link>
                        </li>
                        <li onClick={handleShowNavbar}>
                            <Link to="/portfolio" className={location.pathname.includes("/portfolio") ? "active" : ""}>Portfolio</Link>
                        </li>
                        {/* <li onClick={handleShowNavbar}>
                            <Link to="/fabrics" className={location.pathname.includes("/fabrics") ? "active" : ""}>Fabrics</Link>
                        </li> */}
                        <li className='contact-us-button-mobile' onClick={handleShowNavbar}>
                            <Link to="/contact-us" className={location.pathname.includes("/contact-us") ? "active" : ""}>Contact Us</Link>
                        </li>
                    </ul>
                </div>
                <Link to="contact-us" className='contact-us-button-desktop'>
                    <button className='secondary'>Contact Us</button>
                </Link>
            </div>
        </nav>
    )
}
