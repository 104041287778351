import React from 'react';
import { Cards, PageHeading, ProjectBanner, Testimonials } from '../components';
import "../styles/Service.css";
import { Helmet } from "react-helmet";

export const Upholstery = () => {

  const furnitureUpholsteryCards = [
    {
      imageUrl: require('../assets/upholstered-bed.jpg'),
      imageAlt: "upholstered bedframe and matching curtains with upholstered cornice",
      title: "Bed & Headboard Upholstery",
      description: "Personalize your bedroom retreat with custom bed and headboard upholstery, tailoring comfort and style to your unique preferences.",
    },
    {
      imageUrl: require('../assets/upholstered-sofa.jpg'),
      imageAlt: "white upholstered sofa",
      title: "Sofa Upholstery",
      description: "Revitalize your living space effortlessly with expert sofa upholstery, blending quality craftsmanship with your distinct design vision.",
    },
    {
      imageUrl: require('../assets/upholstered-armchair.jpg'),
      imageAlt: "classic upholstered armchair",
      title: "Armchair Upholstery",
      description: "Elevate any room's ambiance with tailored armchair upholstery, harmonizing comfort and elegance for a refined aesthetic.",
    },
    {
      imageUrl: require('../assets/upholstered-chaise.jpg'),
      imageAlt: "upholstered chaise and matching curtains with upholstered cornices",
      title: "Recliner & Chaise Upholstery",
      description: "Experience luxurious comfort and style transformation with recliner and chaise upholstery, elevating relaxation to a new level of sophistication.",
    },
    {
      imageUrl: require('../assets/upholstered-chairs.jpg'),
      imageAlt: "upholstered modern chairs",
      title: "Chair Upholstery",
      description: " Renew the charm of your chairs with custom upholstery, offering a diverse range of fabrics and designs to seamlessly complement your home decor.",
    },
    {}
  ]

  const wallUpholsteryCards = [
    {
      imageUrl: require('../assets/upholstered-residential-wall.jpg'),
      imageAlt: "upholstered bed wall",
      title: "Residential",
      description: "Transform your home with our residential wall upholstery service. Our expert craftsmen apply luxurious fabrics to create cozy and stylish living spaces tailored to your unique taste.",
    },
    {
      imageUrl: require('../assets/upholstered-commercial-wall.jpg'),
      imageAlt: "upholstered commercial wall",
      title: "Commercial",
      description: "Impress clients with our commercial wall upholstery service. Elevate your business environment with visually stunning and professional wall treatments crafted to reflect your brand's aesthetic and vision.",
    }
  ]

  return (
    <div className='page service-page upholstery-page'>
      <Helmet>
        <title>Empire City Interiors - Furniture & Wall Upholstery in NYC</title>
        <meta
          name="description"
          content="Rediscover the allure of your furniture with our expert upholstery service. From sofas to chairs, bed frames to ottomans, we breathe new life into your beloved pieces. "
        />
      </Helmet>
      <PageHeading title="Upholstery In New York" />

      <div className='container pd-32'>
        <h2>Furniture Upholstery</h2>
        <p className='service-description'>
          Rediscover the allure of your furniture with our expert upholstery service. From sofas to chairs, bed frames to ottomans,
          we breathe new life into your beloved pieces. Our skilled artisans meticulously reinvigorate each item, offering tailored
          solutions that seamlessly blend comfort, durability, and style.
        </p>
        <Cards cardList={furnitureUpholsteryCards} />
      </div>

      <div className='light-background'>
        <div className='container pd-32 wall-upholstery'>
          <h2>Wall Upholstery</h2>
          <p className='service-description'>
            Often overlooked, walls present a canvas for creativity and luxury. Our expert craftsmen meticulously apply sumptuous fabrics
            to your walls, transforming them into tactile and visually stunning focal points. From classic elegance to modern sophistication,
            our tailored solutions ensure a seamless integration of style and functionality.
          </p>
          <Cards cardList={wallUpholsteryCards} />
        </div>
      </div>

      <ProjectBanner />

      <Testimonials />
    </div>
  )
}
