import { useForm } from '@formspree/react';
import React, { useState } from 'react';
import locationIcon from "../assets/address-icon-2.png";
import emailIcon from "../assets/email-icon-2.png";
import phoneIcon from "../assets/phone-icon-2.png";
import { PageHeading } from '../components';
import "../styles/ContactUs.css";
import { Helmet } from "react-helmet";

export const ContactUs = () => {

  // CHANGE FORM CODE
  const [state, handleSubmit] = useForm('mzbnakpj', {
    data: {
      function() {
        setShowSubmitMessage(true);
      }
    }
  });

  const [showSubmitMessage, setShowSubmitMessage] = useState(false)

  return (
    <div className='page contact-us'>
      <Helmet>
        <title>Empire City Interiors - Contact Us</title>
        <meta
          name="description"
          content="Contact us today! Call us at (347) 640-1116 or fill out a contact form online. You can also email us at empirecityinteriors@gmail.com"
        />
      </Helmet>

      <PageHeading title="Contact Us" />

      <div className='bg' />
      <div className="container">
        <div className='form-container'>
          <h2>Get In Touch</h2>
          <p>We are here for you! How can we help?</p>

          <form onSubmit={handleSubmit} id="form">
            <input name="subject" type="hidden" value="New message from {{ name }}" />
            <div className='input-fields'>
              <div className='input-field'>
                <labe for="full-name">
                  Full Name <span>*</span>
                </labe>
                <input type="text" name="name" id="full-name" placeholder="First and Last" required />
              </div>
              <div className='input-field'>
                <label for="email-address">
                  Email Address <span>*</span>
                </label>
                <input type="email" name="email" id="email-address" placeholder="email@domain.tld" required />
              </div>
              <div className='input-field'>
                <label for="phone-number">
                  Phone Number <span>*</span>
                </label>
                <input type="tel" name="phone" id="phone-number" placeholder="(555) 555-5555" required />
              </div>
              <div className='input-field'>
                <label for="message">
                  Message <span>*</span>
                </label>
                <textarea type="text" name="message" id="message" placeholder="I have a question about..." required rows="5" />
              </div>
            </div>
            {!state.submitting && !state.succeeded &&
              <button className='primary-2' type="submit" >
                Send Message
              </button>
            }
          </form>

          {showSubmitMessage &&
            <div className='submit-message'>
              {!state.submitting && state.succeeded &&
                <p>Thank you for contacting us! We will get back to you as soon as possible.</p>
              }
              {!state.submitting && !state.succeeded &&
                <p>Something went wrong... Please try again.</p>
              }
            </div>
          }
        </div>
        <div className='contact-info'>
          <div>
            <div className='contact-icon'>
              <img src={emailIcon} alt="email icon" />
            </div>
            <a href="mailto:empirecityinteriors@gmail.com">
              empirecityinteriors@gmail.com
            </a>
          </div>
          <div>
            <div className='contact-icon'>
              <img src={locationIcon} alt="location pin icon" />
            </div>
            <a href="https://maps.app.goo.gl/iFjWPfnTMtzpkJYdA">
              333 W 57th St. New York, NY 10019
            </a>
          </div>
          <div>
            <div className='contact-icon'>
              <img src={phoneIcon} alt="phone icon" />
            </div>
            <a href="tel:347-640-1116">
              +1 (347) 640-1116
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
