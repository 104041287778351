import React from 'react';
import { ImageGallery, PageHeading } from '../components';
import useFetch from '../hooks/useFetch';
import { Helmet } from "react-helmet";

export const Portfolio = () => {

  const portfolioImages = useFetch('portfolioGallery', 'images');

  return (
    <div className='page portfolio'>
      <Helmet>
        <title>Empire City Interiors - Portfolio</title>
        <meta
          name="description"
          content="Explore our portfolio to witness the artistry that goes into every piece we create. From custom-made headboards to beautifully upholstered sofas, our work speaks for itself."
        />
      </Helmet>
      <PageHeading title="Our Work" />
      <div className="container pd-32">
        <p className="portfolio-text">
          Explore our portfolio to witness the artistry that goes into every piece we create.
          From custom-made headboards to beautifully upholstered sofas, our work speaks for itself.
          Be inspired by our previous projects and imagine the possibilities for your own space.
        </p>
        {portfolioImages && portfolioImages.data && portfolioImages.data.length !== 0 &&
          <ImageGallery data={portfolioImages} />
        }
      </div>
    </div>
  )
}
