import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useFetch from '../hooks/useFetch';
import "../styles/Testimonials.css";

export const Testimonials = () => {

  const testimonials = useFetch('review', 'review');

  return (
    <>
      {testimonials && testimonials.data && testimonials.data.length !== 0 &&
        <div className='testimonials container pd-56'>
          <h2>Testimonials</h2>
          <p className='subtitle'>What Our Clients Say</p>

          <Carousel autoPlay={true} infiniteLoop={true} showArrows={false} showIndicators={true} showStatus={false} showThumbs={false}>
            {testimonials.data.map((testimonials) => {
              return (
                <div className="carousel-item">
                  <p className='star-rating'>★★★★★</p>
                  <p className='review-text'>"{testimonials.review}"</p>
                  <p className='review-author'>- {testimonials.author}</p>
                </div>
              )
            })}
          </Carousel>
        </div>
      }
    </>
  )
}
