import leftArrow from "../assets/arrow-left.png";
import rightArrow from "../assets/arrow-right.png";

const Modal = ({
  clickedImg,
  setClickedImg,
  handelRotationRight,
  handelRotationLeft
}) => {
  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      setClickedImg(null);
    }
  };

  return (
    <>
      <div className="overlay dismiss" onClick={handleClick}>
        <img className="big-image" src={clickedImg} alt="bigger pic" />
        <div className="arrows">
          <img onClick={handelRotationLeft} src={leftArrow} alt="left arrow icon" />
          <img onClick={handelRotationRight} src={rightArrow} alt="right arrow icon" />
        </div>
      </div>
    </>
  );
};

export default Modal;