import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import addressIcon from '../assets/address-icon-2.png';
import emailIcon from '../assets/email-icon-2.png';
import logo from '../assets/empire-city-interiors-logo-white.png';
import instagramIcon from '../assets/instagram-icon-2.png';
import facebookIcon from '../assets/facebook-icon-2.png';
import phoneIcon from '../assets/phone-icon-2.png';
import "../styles/Footer.css";

export const Footer = () => {

    const location = useLocation();

    return (
        <div>
            {/* CTA */}
            {!location.pathname.includes("/contact-us") &&
                <div className='cta'>
                    <div className="container pd-32">
                        <h2>Ready to Transform Your Space?</h2>
                        <a href="tel:347-640-1116">
                            <button className='secondary'>Call Us Today</button>
                        </a>
                    </div>
                </div>
            }

            {/* FOOTER */}
            <div className='footer'>
                <footer className='container'>
                    <div className='footer-content'>

                        {/* BRAND */}
                        <div className='footer-brand'>
                            <Link to="/" className='footer-logo'>
                                <img src={logo} alt="Empire City Interiors Logo" />
                            </Link>
                            <p>
                                Transforming Spaces with Style and Comfort
                            </p>
                            <div className='footer-socials'>
                                <a href="https://www.instagram.com/chenille_expo" target="_blank" rel="noopener noreferrer">
                                    <img src={instagramIcon} alt="Instagram Icon" />
                                </a>
                                <a href="https://www.facebook.com/profile.php?id=61558107428054" target="_blank" rel="noopener noreferrer">
                                    <img src={facebookIcon} alt="Facebook Icon" />
                                </a>
                            </div>
                        </div>

                        {/* CONTACTS */}
                        <div className='footer-info'>
                            <p className='footer-title'>
                                Get In Touch
                            </p>
                            <div className='footer-info-column'>
                                <a href="mailto:empirecityinteriors@gmail.com">
                                    <img src={emailIcon} alt="email icon" />
                                    <p>empirecityinteriors@gmail.com</p>
                                </a>
                                <a href="https://maps.app.goo.gl/qM4uaXS1ZkJFM1FS9" target="_blank" rel="noopener noreferrer" >
                                    <img src={addressIcon} alt="address icon" />
                                    <p>​333 W 57th St. New York, NY 10019</p>
                                </a>
                                <a href="tel:3476401116">
                                    <img src={phoneIcon} alt="phone icon" />
                                    <p>+1 (347) 640-1116</p>
                                </a>
                            </div>
                        </div>

                        {/* QUICK LINKS */}
                        <div className='footer-links'>
                            <p className='footer-title'>
                                Quick Links
                            </p>
                            <div className='footer-links-column'>
                                {/* <Link to="/about-us">About Us</Link> */}
                                <Link to="/window-treatments">Window Treatments</Link>
                                <Link to="/upholstery"><p>Upholstery</p></Link>
                                <Link to="/custom-furniture">Custom Furniture</Link>
                                <Link to="/portfolio">Portfolio</Link>
                            </div>
                        </div>
                    </div>

                    <hr />

                    {/* COPYRIGHT */}
                    <div className='footer-copyright'>
                        <p>Copyright © Empire City Interiors. Developed By
                            <a href="https://www.linkedin.com/in/smilteval/" target='_blank' rel="noopener noreferrer"> Smilte V.</a>
                        </p>
                    </div>
                </footer>
            </div>
        </div>
    )
}
